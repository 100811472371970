.headContainer {
  text-align: center;
  background: var(--er-primaryLighter);
  padding: 80px;
  margin: 80px 0;
  border-radius: 14px;
}

.headContainer h3 {
  margin-top: 0;
}

.headContainer input {
  width: 600px;
  padding: 20px 30px;
  border: 0;
  font-size: 16px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.headContainer input:focus-visible {
  outline: 0;
}

.qnaContainer {
  margin: 8px 0;
}

.questionContainer {
  padding: 20px;
  border-radius: 6px;
  background: whitesmoke;
  cursor: pointer;
}

.answerContainer {
  height: 0;
  padding: 0;
  margin: 0;
  border-radius: 6px;
  background: var(--er-grey);
  color: white;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s ease, transform 1s ease;
  transform-origin: top;
}

.answerContainerSelected {
  padding: 20px;
  height: auto;
  padding-left: 30px;
  margin: 4px 0;
  opacity: 1;
  transform: scaleY(1);
}

.questionChevron {
  float: right;
  transition: all 0.2s ease-in-out;
}

.questionChevronUp {
  transform: rotate(180deg);
}

.contactUsContainer {
  margin-top: 120px;
  background: var(--er-infoLight);
  padding: 60px;
  border-radius: 14px;
  margin-bottom: 60px;
}

.contactUsContainer > div:first-of-type {
  display: inline-block;
  width: calc(60% - 80px);
  padding: 40px;
  text-align: center;
  vertical-align: top;
}

.contactUsContainer > div:first-of-type h3 {
  margin-top: 70px;
}

.contactUsContainer > div:first-of-type span {
  font-size: 18px;
}

.contactUsContainer > div:nth-of-type(2) {
  display: inline-block;
  width: calc(40% - 80px);
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  background: white;
}
