.forget-password-a {
  cursor: pointer;
  color: cornflowerblue;
  font-size: 12px;
  width: max-content;
  text-align: left;
  margin-bottom: 12px;
  display: block;
  cursor: pointer;
  color: cornflowerblue;
}

.remember-me {
  width: 100%;
  text-align: left;
}

.loginBox {
  text-align: center;
  margin: 100px;
}
