.qr-api-reference {
  line-height: 1.6;
  padding: 0 20px;
}

.qr-api-reference h1 {
  color: #333;
  font-size: 2rem;
}

.qr-api-reference h2 {
  color: #555;
  font-size: 1.5rem;
}

.qr-api-reference h3 {
  color: #777;
}

.subTr > td:first-of-type {
    position: relative;
    padding-left: 40px;
}

.subTr {
    color: var(--er-green);
}

.subSubTr > td:first-of-type {
    position: relative;
    padding-left: 60px;
}

.subSubTr {
    color: var(--er-purple);
}

.subSubTr .cdrIcon {
    left: 40px;
}

.subSubSubTr > td:first-of-type {
    position: relative;
    padding-left: 80px;
}

.subSubSubTr {
    color: var(--er-infoDark);
}

.subSubSubTr .cdrIcon {
    left: 60px;
}

.cdrIcon {
    position: absolute;
    color: black;
    width: 12px;
    height: 12px;
    left: 20px;
}

.dataFormattingContainer {
    list-style: none;
    padding: 0;
}

.dataFormattingContainer li {
    padding: 10px 0;
}

.preContainer {
    background: var(--er-greyLight);
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.preContainerWidth {
    width: 350px;
}
