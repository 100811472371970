.dashboard-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin-top: 30px;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0px);
  }
  
  .dashboard-button span {
    margin-right: 8px;
  }
  
  .dashboard-button svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
  