#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  /* Colors */

  --er-offWhite: #f3f2f4;
  --er-lightGrey: #e8e5e9;
  --er-focusOrange: #f6c15e;
  --er-purple: #705ce4;
  --er-red: #d82a1d;

  --er-primary: #ff9a00;
  --er-primaryLight: #ffbf40;
  --er-primaryDark: #cc7d00;
  --er-primaryULight: #ffbf4026;

  --er-primaryLighter: #fff0d9;

  --er-background: mintcream;
  --er-secondaryBackground: white;

  --er-grey: #6c757d;
  --er-greyLight: #e8e5e9;
  /* --er-greyLight: #a1abb2; */
  --er-greyDark: #454d53;

  --er-font: rgb(32, 32, 32);
  --er-fontDark: rgb(8, 8, 8);
  --er-fontLight: rgb(70, 70, 70);

  --er-green: #198754;
  --er-greenLight: #2fad76;
  --er-greenDark: #0e5a35;

  --er-pista: #c7e0b9;
  --er-pistaLight: #daf1d5;
  --er-pistaDark: #60775a;
  --er-pistaBorder: #b5cdab;
  
  --er-red: #dc3545;
  --er-redLight: #f36c7d;
  --er-redDark: #a82b3a;

  --er-error: #e6b3af;
  --er-errorLight: #eec8c5;
  --er-errorDark: #b1352d;
  --er-errorBorder: #cd9693;

  --er-yellow: #ffc107;
  --er-yellowLight: #ffdd4d;
  --er-yellowDark: #e0a400;

  --er-warn: #efe4ac;
  --er-warnLight: #f8f4c4;
  --er-warnDark: #9b732e;
  --er-warnBorder: #d7d487;

  --er-blue: #0dcaf0;
  --er-yellowLight: #45e0f9;
  --er-yellowDark: #08a6c5;

  --er-info: #a7d6f0;
  --er-infoLight: #cde9f7;
  --er-infoDark: #427fac;
  --er-infoBorder: #7bb9e0;

  /* --cnf-dark2: #05386B;
  --cnf-dark: #379683;
  --cnf-normal: #5CDB95;
  --cnf-light: #8EE4AF;
  --cnf-light2: #EDF5E1; */

  --cnf-dark2: #17252a;
  --cnf-dark: #2b7a78;
  --cnf-normal: #3aafa9;
  --cnf-light: #def2f1;
  --cnf-light2: #feffff;

  /* --cnf-dark2: #1A1A1D;
  --cnf-dark: #4E4E50;
  --cnf-normal: #6F2232;
  --cnf-light: #950740;
  --cnf-light2: #C3073F; */

  /* --cnf-dark2: #8d8741;
  --cnf-dark: #659DBD;
  --cnf-normal: #DAAD86;
  --cnf-light: #BC986A;
  --cnf-light2: #FBEEC1; */
  --box-shadow-1: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: grey;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: lightgray;
  margin: 8px;
}

body {
  min-height: 100vh;
  background-color: var(--er-background);
  overflow: hidden;
}

p {
  line-height: 1.6rem;
}

.mainContainer {
  padding: 0px 60px 0px 60px;
  max-width: 1920px;
  width: calc(100% - 120px);
  min-height: calc(100vh - 440px);
  background:  var(--er-secondaryBackground);
  transition: all 0.3s ease;
}

.mainScrollableSection {
  overflow: auto;
  transition: all 0.3s ease-in-out;
}
/* #EFEFEF */

@media screen and (min-width: 2040px) {
  .mainContainerHolder {
    margin-left: calc((100vw - 2040px)/2);
  }
}

@media (max-width: 960px) {
  .mainContainer {
    padding: 0px 30px 30px 30px;
    width: calc(100% - 60px);
  }
}