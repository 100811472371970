.dropdownButton svg {
  z-index: 11;
}

.profileDropdownButton ul {
  right: -20px;
  left: unset;
}

.profileDropdownButton ul li:last-of-type:hover {
  color: var(--er-red);
}

.profileIcon {
  vertical-align: 'middle';
  padding-right: '10px';
}

.navbar {
  background-color: var(--er-primary);
  color: #fff;
  padding: 15px;
  width: calc(100vw - 30px);
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  transition: height 0.3s ease-in-out;
  display: flex;
  justify-content: center;
}

.nbSectionContainer {
  display: grid;
  padding: 0 20px;
  grid-template-columns: repeat(3, 33.3333%);
  -webkit-box-align: center;
  align-items: center;
  max-width: 2040px;
  width: 100%;
}

.logoContainerHolder {
  text-align: left;
  min-width: 342px;
}

.nbH1 {
  display: inline-block;
  font-size: 50px;
  margin: 0px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 960px) {
  .nbSectionContainer {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, 33.3333%);

    & * {
      text-align: center;
    }
  }

  .navbar {
    /* height: 130px!important; */
  }
}
