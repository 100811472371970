@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .customInputBtns[type='checkbox'] {
    position: absolute;
  }

  .customInputBtns[type='checkbox'],
  .customInputBtns[type='radio'] {
    /* --active: #275efe; */
    --active: var(--er-primary);
    --active-inner: #fff;
    --focus: 2px var(--er-primaryDark);
    /* --border: #bbc1e1; */
    --border: var(--er-greyLight);
    /* --border-hover: #275efe; */
    --border-hover: var(--er-primaryLight);
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 10px;
      max-width: calc(100% - 32px);
    }
  }
  .customInputBtns[type='checkbox'] {
    &:not(.switch) {
      border-radius: 6px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 6px;
        top: 3px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .customInputBtns[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    &:checked {
      --s: 0.5;
    }
  }
}

/* Start of CSS for Slider */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-minmax {
  margin-top: 30px;
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #ffffff;
}

.range-slider {
  width: 100%;
  position: relative;
}

.rs-range {
  margin-top: 29px;
  width: calc(100% - 80px);
  margin-left: 40px;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -20px;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.rs-label {
  position: relative;
  transform-origin: center center;
  display: block;
  width: 98px;
  height: 98px;
  background: transparent;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 22px;
  box-sizing: border-box;
  border: 2px solid #fff;
  margin-top: 20px;
  margin-left: -38px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 36px;
  &::after {
    display: block;
    font-size: 20px;
    letter-spacing: 0.07em;
    margin-top: -2px;
  }
}

.psuedo-content {
  color: white;
  position: absolute;
}

/* Classes for primary color */

.box-minmax-primary {
  color: var(--er-primary);
}

.rs-range-primary {
  &::-webkit-slider-runnable-track {
    background: var(--er-primary);
    border-color: var(--er-primaryDark);
  }

  &::-webkit-slider-thumb {
    border-color: var(--er-primary);
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.75);
    background: var(--er-primary);
  }

  &::-moz-range-track {
    background: var(--er-primary);
    border-color: var(--er-primaryDark);
  }

  &::-moz-range-thumb {
    border-color: var(--er-primary);
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.75);
    background: var(--er-primary);
  }
}

.rs-label-primary {
  border-color: var(--er-primary);
  color: var(--er-primary);
}

.psuedo-content-primary {
  color: var(--er-primary);
}

/* Classes for red color */

.box-minmax-red {
  color: var(--er-red);
}

.rs-range-red {
  &::-webkit-slider-runnable-track {
    background: var(--er-red);
    border-color: var(--er-redDark);
  }

  &::-webkit-slider-thumb {
    border-color: var(--er-red);
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.75);
    background: var(--er-red);
  }

  &::-moz-range-track {
    background: var(--er-red);
    border-color: var(--er-redDark);
  }

  &::-moz-range-thumb {
    border-color: var(--er-red);
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.75);
    background: var(--er-red);
  }
}

.rs-label-red {
  border-color: var(--er-red);
  color: var(--er-red);
}

.psuedo-content-red {
  color: var(--er-red);
}

.psuedo-content > span {
  position: relative;
  top: -36px;
  left: 0.5px;
}
/* End of CSS for Slider */
