.qrContainer {
  padding: 20px;
  padding-top: 40px;
  margin: 20px 0;
  width: calc(100% - 120px);
  min-width: 600px;
  border-radius: 8px;
  border: 1px solid lightgray;
}

.searchContainer {
  padding: 20px;
  width: max-content;
  border: 1px solid lightgray;
  border-radius: 12px;
  margin: 20px 0;
}

.featureContainer {
  display: inline-block;
  margin: 20px;
}

.featureContainer > svg,
.featureContainer > span {
  vertical-align: middle;
}

.featureContainer > svg {
  margin-right: 12px;
}

.filterIconContainer {
  margin-right: 40px;
  float: right;
  cursor: pointer;
  color: var(--er-red);
}

.filterXIcon {
  position: absolute;
  width: 12px;
  margin-left: -10.6px;
  stroke-width: 3px;
  margin-top: 2px;
}

.idSpan {
  margin: 20px;
  background: lightgray;
  padding: 6px 16px;
  border-radius: 6px;
}

.idSpan > span:first-of-type {
  font-weight: 600;
}

.disableEdit,
.disableSecure {
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(100%);
}

.sliderValueClass {
  padding-top: 35px;
  font-size: 20px;
}

.statSpan {
  border-bottom: 1px solid var(--er-primary);
  padding: 4px 10px;
}

.detailsContainer > div {
  display: grid;
  grid-template-columns: repeat(2, 200px calc(100% - 200px));
  margin: 16px 0;
  font-size: 18px;
}

.detailsContainer > div > span:first-of-type {
  font-weight: 600;
}

.buttonsContainer {
  float: right;
  margin-top: -8px;
}

.buttonsContainer > button {
  margin: 0 30px 10px 0;
}

.buttonsContainer > button > span,
.buttonsContainer > button > svg,
.buttonsContainer > button {
  vertical-align: middle;
}

.buttonsContainer > button > svg {
  margin-right: 15px;
}

.verifyPassButtonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 12px;
}

.verifyPassButtonContainer > span {
  font-size: 24px;
  padding: 12px;
  border-radius: 50%;
  border: 1px solid lightgray;
}

.unlockDiv {
  height: 150px;
  width: 100%;
  background: var(--er-primaryLighter);
  border-radius: 10px;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
}

.unlockDiv > svg {
  width: 72px;
  height: 72px;
  margin-top: 10px;
}

.unlockDiv > span {
  display: block;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.filesSection {
  margin: 20px;
}

.filesContainer {
  vertical-align: middle;
  display: grid;
  grid-template-columns: calc(100% - 68px) 39px 29px;
  margin: 12px 0;
  border: 1px solid var(--er-greyLight);
  border-radius: 5px;
  padding: 10px;
}

.manageAPILeftContainer {
  width: 60%;
  min-width: 683px;
  display: inline-block;
  vertical-align: top;
}

.multipleSourceDownloadList {
  padding: 0;
  margin: 0;
  list-style: none;
  background: white;
  width: max-content;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
}

.multipleSourceDownloadList li {
  padding: 0px 20px;
  padding-top: 10px;
}

.multipleSourceDownloadList li:last-of-type {
  margin-bottom: 10px;
}

.multipleSourceDownloadList li:hover {
  color: var(--er-primary)
}

.multipleSourceDownloadContainer {
  position: relative;
  display: inline-block;
}