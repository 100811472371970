.section {
  display: inline-block;
  width: calc(100% - 860px);
  padding: 80px;
  vertical-align: top;
  transition: all 0.3s ease;
}

.section h2 {
  margin-top: 0px;
  margin-bottom: 60px;
}

.section p {
  margin-bottom: 2em;
}

.sectionContainer {
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1388px) {
  .section {
    width: calc(100% - 160px);
  }
}

@media only screen and (max-width: 960px) {
  .section {
    width: calc(100% - 80px);
    padding: 40px;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .section {
    width: calc(100% - 40px);
    padding: 20px;
    padding-top: 80px;
  }
}
