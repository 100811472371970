.cDown {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    margin-top: 3px;
    margin-right: 5px;
    stroke-width: 1px
}

.inputContainer {
    position: relative;
}

.inputLabel {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.customSelectInput {
    width: calc(100% - 12px);
    padding: 7px 5px;
    border-radius: 5px;
    border: 1px solid;
    font-size: 13px;
}

.customSelectInput:focus-visible {
    outline: 1px solid var(--er-primary);
    border-color: var(--er-primary);
}

.inputSubContainer {
    position: relative;
    margin-top: 5px;
}

.optionsContainer {
    list-style: none;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    width: calc(100% - 3.25px);
    background: white;
    z-index: 5;
    max-height: 300px;
    overflow: auto;
}

.optionsContainer li {
    cursor: pointer;
    padding: 5px 10px;
    position: relative;
    font-size: 13px;
}

.optionsContainer li:hover {
    color: var(--er-primary);
}

.checkIcon {
    scale: 0.7;
    position: absolute;
    right: 5px;
    top: 2px;
    stroke-width: 1px
}

.emptyList {
    padding: 5px 10px;
    color: var(--flg-grey);
}