.qr-read-me {
  line-height: 1.6;
  padding: 0 20px;
}

.qr-read-me h1 {
  color: #333;
  font-size: 2rem;
}

.qr-read-me h2 {
  color: #555;
  font-size: 1.5rem;
}

.qr-read-me h3 {
  color: #777;
}

.headingSpan {
  font-weight: 600;
}

.uList {
  list-style-type: circle;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.uList li {
  display: list-item;
  margin: 8px;
}
