.mainSubBox {
  display: inline-block;
  width: 26%;
  min-width: 20rem;
  height: 350px;
  background: white;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 2rem;
  vertical-align: top;
}

.mainSubBox > div {
    text-align: center;
}

.getStartedBtn {
    margin: 100px auto 50px;
    padding: 1.5rem 5rem;
    font-size: 16px;
    text-transform: uppercase;
    width: max-content;
    font-weight: 600;
    border-radius: 12px;
    background: blue;
    color: white;
    border: 1px solid blue;
    position: relative;
    cursor: pointer;
}

.getStartedBtn > svg {
    position: absolute;
    right: 16px;
    top: 21.5px;
}

/* .getStartedBtn > span,
.getStartedBtn > svg {
    vertical-align: middle;
} */