.documentation {
  line-height: 1.6;
  margin: 20px;
}

.documentation h1 {
  color: #333;
}

.documentation h2 {
  color: #555;
}

.documentation h3 {
  color: #777;
}

.documentation p {
  margin-bottom: 15px;
}

.documentation code {
  background-color: #f4f4f4;
  padding: 2px 5px;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
}

.documentation img {
  max-width: 100%;
}
