.productTable th {
  text-align: left;
}

.productTable th:nth-of-type(5) {
  width: 110px;
  max-width: 110px;
  text-align: center;
}

.productTable td:nth-of-type(6) > div {
  text-align: center;
}

.productTable td:first-of-type,
.productTable th:first-of-type {
  text-align: center;
}

.productTable th:nth-of-type(1),
.productTable th:nth-of-type(3),
.productTable th:nth-of-type(4) {
  width: 150px;
}

.productTable th:nth-of-type(2) {
  width: 90px;
}

.productTable th:nth-of-type(6) {
  width: 25px;
}

.infoContainer {
  font-size: 14px;
  position: relative;
  padding: 50px 0;
  color: var(--er-grey);
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
  width: 100%;
}

.generationDialog {
  min-height: unset !important;
  max-width: 600px;
}

.generationDialog > h4 {
  margin-top: 0;
}

.currentPlanHeading {
  color: var(--er-primary);
  padding-left: 5px;
  font-size: 16px;
}

.planLinesContainer {
  border: 2px solid var(--er-primaryLight);
  padding: 20px;
  border-radius: 5px;
  margin-top: 12px;
  width: calc((100% - 95.5px) / 2);
  display: inline-block;
}

.planLinesContainer h4 {
  margin: 0;
  font-size: 16px;
  margin-bottom: 7px;
}

.planLinesContainer span {
  padding: 5px 0;
  font-size: 13px;
}

.selectPlanDialog {
  width: 500px;
}

.selectPlanDialog h4 {
  margin-top: 0;
}

.dashboardMoreOptions {
  right: 52px;
  top: 10px;
}

.downloadInvoiceBtn {
  position: absolute;
  bottom: 30px;
}

.status-red {
  background: var(--er-errorLight);
  color: var(--er-errorDark);
  border-radius: 8px;
  border: 1px solid var(--er-errorBorder);
  padding: 8px 12px;
}

.status-green {
  background: var(--er-pistaLight);
  color: var(--er-pistaDark);
  border-radius: 8px;
  border: 1px solid var(--er-pistaBorder);
  padding: 8px 12px;
}

.status-yellow {
  background: var(--er-warnLight);
  color: var(--er-warnDark);
  border-radius: 8px;
  border: 1px solid var(--er-warnBorder);
  padding: 8px 12px;
}
