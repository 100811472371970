.icon {
  width: 150px;
  height: 150px;
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px) rotate(-10deg);
  }
  40% {
    transform: translateX(10px) rotate(10deg);
  }
  60% {
    transform: translateX(-10px) rotate(-10deg);
  }
  80% {
    transform: translateX(10px) rotate(10deg);
  }
  100% {
    transform: translateX(0);
  }
}

.loader-container div {
  border-color: red transparent transparent transparent !important;
  height: 420px !important;
  width: 420px !important;
  margin-top: -10px !important;
  margin-left: -10px !important;
}