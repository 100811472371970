.col-3 {
  width: 100%;
  position: relative;
  text-align: left;
}

.customInput {
  font: 15px/24px 'Lato', Arial, sans-serif;
  color: var(--er-font);
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
}

.effect-16 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid var(--er-greyDark);
  background-color: transparent;
}

.effect-16 ~ .focus-border {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--er-primary);
  transition: width 0.4s;
}

.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 100%;
  transition: width 0.4s;
}

.effect-16 ~ label {
  position: absolute;
  text-transform: uppercase;
  z-index: 1;
  left: 0;
  width: 100%;
  top: 9px;
  color: var(--er-greyDark);
  transition: 0.3s;
  letter-spacing: 0.5px;
  font-size: 15px;
}

.customInput:auto-fill {
  background: pink;
}

.customInput:valid ~ label,
.effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -16px;
  z-index: 1;
  font-size: 12px;
  color: var(--er-primary);
  transition: 0.3s;
}

.effect-16:focus-visible {
  outline: none;
}

.error span:nth-of-type(1) {
  background-color: var(--er-red);
  top: 31px;
  width: 100%;
}

.error label {
  color: var(--er-red) !important;
}

.error span:nth-of-type(2) {
  color: var(--er-red);
  font-size: 14px;
  position: relative;
  top: 5px;
}

.error input {
  padding-right: 24px;
}

.error svg {
  position: absolute;
  top: 4px;
  right: 0;
  width: 20px;
  color: var(--er-red);
}
