.footerImage {
  position: relative;
  z-index: 1;
  /* background-image: url('../../assets/images/footer-image.png'); */
  background-color: var(--er-primary);
  background-size: cover;
  height: 310px;
}

.footerImage > img {
  vertical-align: bottom;
}

.footer-policies {
  display: grid;
  margin-top: 8px;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.footer-policies > a {
  font-size: 12px;
}

.footer-policies > a:first-of-type {
  text-align: left;
}

.footer-policies > a:last-of-type {
  text-align: right;
}

.mainFooter {
  max-width: 2040px;
  width: 100%;
  height: 290px;
  color: white;
  position: absolute;
  bottom: 0px;
  top: unset;
  z-index: 2;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.mainFooter > div > h1 {
  font-weight: 600;
  font-size: 36px;
  margin: 0;
  margin-top: 50px;
  vertical-align: top;
  padding: 40px;
}

.marginController {
  margin-top: 100px;
}

.marginControllerSB {
  margin-top: 70px;
}

@media screen and (max-width: 1388px) {
  .mainFooter > div > h1 {
    margin-top: 35px;
  }
}

@media screen and (max-width: 1150px) {
  .mainFooter > div > h1 {
    margin-top: 15px;
  }
}

@media screen and (max-width: 900px) {
  .mainFooter {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, calc(100% / 3));
    top: 0px;
    bottom: unset;
  }

  .mainFooter > div > h1 {
    margin-top: 0px;
    padding: 20px 40px;
  }

  .marginController {
    margin-top: 20px;
  }

  .marginControllerSB {
    margin-top: 20px;
  }

  .footerImage {
    height: 330px;
  }
}

@media screen and (max-width: 550px) {
  .mainFooter > div > h1 {
    margin-top: 0px;
    padding: 20px 40px;
    font-size: 24px;
  }
}

@media screen and (min-width: 2040px) {
  .footerMask,
  .mainFooter {
    margin-left: calc(((100vw - 1920px) / 2) - 60px);
  }
}

@media screen and (min-width: 4000px) {
  .footerImage > img {
    width: 100vw;
  }
}
