.auSectionContainerAlt,
.auSectionContainer {
    margin: 100px 0
}

.auSectionContainerAlt {
    border-radius: 20px;
    background: bisque;
}

.sectionPara {
    font-size: 24px;
    padding: 125px;
    width: calc(50% - 250px);
    display: inline-block;
    vertical-align: middle;
}

.sectionImageContainer {
    padding: 50px;
    width: calc(50% - 100px);
    display: inline-block;
    vertical-align: middle;
}

.sectionImage {
    width: 100%;
    border-radius: 8px;
}