.colorBlock {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border-radius: 4px;
    /* border: 1px solid black; */
    margin: 0 10px;
}

.colorButton {
    background: white;
    display: inline-block;
    padding: 8px;
    border-radius: 4px;
    margin-left: 10px;
    border: 1px solid black;
}

.colorPickerContainer {
    position: absolute;
    background: white;
    padding: 20px;
    border-radius: 4px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}