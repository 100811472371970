.customDatePickerCalendar {
  padding: 10px;
}

.customDatePickerCalendar .react-datepicker__header {
  background: white;
  border: 0;
}

.customDatePickerCalendar .react-datepicker__current-month {
  padding-top: 6px;
  padding-bottom: 16px;
  font-weight: 400;
}

.customDatePickerCalendar .react-datepicker__navigation {
  top: 18px;
}

.customDatePickerCalendar .react-datepicker__day-name {
  color: var(--er-greyDark);
}

.customDatePickerCalendar .react-datepicker__day--outside-month {
  color: var(--er-grey);
}

.customDatePickerCalendar .react-datepicker__triangle::after {
  border-bottom-color: white !important;
}

.customDatePickerCalendar .react-datepicker__day--keyboard-selected,
.customDatePickerCalendar .react-datepicker__month-text--keyboard-selected,
.customDatePickerCalendar .react-datepicker__quarter-text--keyboard-selected,
.customDatePickerCalendar .react-datepicker__year-text--keyboard-selected,
.customDatePickerCalendar .react-datepicker__day--selected,
.customDatePickerCalendar .react-datepicker__day--in-selecting-range,
.customDatePickerCalendar .react-datepicker__day--in-range,
.customDatePickerCalendar .react-datepicker__month-text--selected,
.customDatePickerCalendar .react-datepicker__month-text--in-selecting-range,
.customDatePickerCalendar .react-datepicker__month-text--in-range,
.customDatePickerCalendar .react-datepicker__quarter-text--selected,
.customDatePickerCalendar .react-datepicker__quarter-text--in-selecting-range,
.customDatePickerCalendar .react-datepicker__quarter-text--in-range,
.customDatePickerCalendar .react-datepicker__year-text--selected,
.customDatePickerCalendar .react-datepicker__year-text--in-selecting-range,
.customDatePickerCalendar .react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: var(--er-primary);
  color: white;
}

.customDatePickerCalendar .react-datepicker__day:hover,
.customDatePickerCalendar .react-datepicker__month-text:hover,
.customDatePickerCalendar .react-datepicker__quarter-text:hover,
.customDatePickerCalendar .react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: var(--er-background);
  outline: 1px solid var(--er-primary);
}

.customDatePickerCalendar .react-datepicker__navigation-icon::before {
  scale: 0.7;
  border-color: black;
  border-width: 1px 1px 0 0;
}

.customDatePickerPopper {
  transform: none !important;
  top: 39px !important;
}

.label .customDatePickerPopper {
  top: 64px !important;
}

.customDatePickerCalendar .react-datepicker__triangle {
  transform: translate3d(80px, 0px, 0px) !important;
}

.customDatePickerContainer {
  width: 100%;
}

.customDatePickerInput {
  border: none;
  width: 100%;
  border-bottom: 1px solid var(--er-grey);
  padding: 5px 22px 2px 0px;
  font-size: 16px;
}

.customDatePickerInput:focus-visible {
  outline: none;
}

.customDatePickerPopper {
  z-index: 9;
}

.mainDateContainer {
  position: relative;
  width: 250px;
  text-align: left;
}

.mainDateContainer i {
  position: absolute;
  background: var(--er-grey);
  right: 2px;
  bottom: 2px;
  scale: 0.7;
}

.bordered .customDatePickerInput {
  padding: 10px 24px 10px 10px;
  border: 1px solid var(--er-grey);
  border-radius: 0px 0px 8px 8px !important;
}

.bordered i {
  bottom: 9px;
}

.calanderIcon {
  margin-right: -20px;
  cursor: pointer;
}
