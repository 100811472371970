.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid black;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


/* .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #1e90ff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
}

.loading-spinner {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loading-dot {
  width: 10px;
  height: 10px;
  background-color: #1e90ff;
  border-radius: 50%;
  animation: bounce 0.8s infinite ease-in-out;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); 
  }
} */

/* .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
}

.loading-spinner {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: rotateZ(45deg);
  animation: rotate 1.5s linear infinite;
}

.loading-line {
  width: 8px;
  height: 24px;
  background-color: #1e90ff;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-line:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-line:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes rotate {
  0% {
    transform: rotateZ(45deg);
  }
  100% {
    transform: rotateZ(405deg);
  }
}

@keyframes pulse {
  0%, 70%, 100% {
    height: 24px;
    transform: scaleY(1);
  }
  35% {
    height: 40px;
    transform: scaleY(1.5);
  }
} */

/* .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8); 
}

.loading-spinner {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: rotateZ(45deg);
  animation: rotate 1.5s linear infinite;
}

.loading-route {
  width: 8px;
  height: 24px;
  background-color: #1e90ff;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-route:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-route:nth-child(3) {
  animation-delay: 0.6s;
}

.loading-route:nth-child(4) {
  animation-delay: 0.9s;
}

@keyframes rotate {
  0% {
    transform: rotateZ(45deg);
  }
  100% {
    transform: rotateZ(405deg);
  }
}

@keyframes pulse {
  0%, 70%, 100% {
    height: 24px;
    transform: scaleY(1);
  }
  35% {
    height: 40px;
    transform: scaleY(1.5);
  }
}

@keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
} */

/* .loader {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.loader .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #ffffff;
  opacity: 0;
  animation: loader 1.5s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.loader .circle:nth-child(1) {
  animation-delay: 0s;
}

.loader .circle:nth-child(2) {
  animation-delay: 0.3s;
}

.loader .circle:nth-child(3) {
  animation-delay: 0.6s;
}

.loader .circle:nth-child(4) {
  animation-delay: 0.9s;
}

.loader .circle:nth-child(5) {
  animation-delay: 1.2s;
}

.loader .circle:nth-child(6) {
  animation-delay: 1.5s;
}

.loader .circle:nth-child(1) {
  border-color: #ff7f00;
}

.loader .circle:nth-child(2) {
  border-color: #1f8ef1;
}

.loader .circle:nth-child(3) {
  border-color: #00d093;
}

.loader .circle:nth-child(4) {
  border-color: #ff4c52;
}

.loader .circle:nth-child(5) {
  border-color: #f9b200;
}

.loader .circle:nth-child(6) {
  border-color: #9b59b6;
} */

/* @keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.loader {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  animation: rotate 1.5s infinite linear;
}

.loader .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: bounce 0.5s infinite ease-in-out;
}

.loader .circle:nth-child(1) {
  left: 50%;
  top: 0;
  animation-delay: -0.4s;
}

.loader .circle:nth-child(2) {
  right: 0;
  top: 50%;
  animation-delay: -0.3s;
}

.loader .circle:nth-child(3) {
  left: 50%;
  bottom: 0;
  animation-delay: -0.2s;
}

.loader .circle:nth-child(4) {
  left: 0;
  top: 50%;
  animation-delay: -0.1s;
}

.loader .circle:nth-child(5) {
  right: 0;
  top: 50%;
  animation-delay: 0s;
}

.loader .circle:nth-child(1) {
  background-color: #ff7f00;
}

.loader .circle:nth-child(2) {
  background-color: #1f8ef1;
}

.loader .circle:nth-child(3) {
  background-color: #00d093;
}

.loader .circle:nth-child(4) {
  background-color: #ff4c52;
}

.loader .circle:nth-child(5) {
  background-color: #f9b200;
} */

/* @keyframes loader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
}

.loader .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 8px;
  animation: loader 1s infinite ease-in-out;
}

.loader .circle:nth-child(1) {
  animation-delay: 0s;
}

.loader .circle:nth-child(2) {
  animation-delay: 0.2s;
}

.loader .circle:nth-child(3) {
  animation-delay: 0.4s;
}

.loader .circle:nth-child(4) {
  animation-delay: 0.6s;
}

.loader .circle:nth-child(1) {
  background-color: #ff7f00;
}

.loader .circle:nth-child(2) {
  background-color: #1f8ef1;
}

.loader .circle:nth-child(3) {
  background-color: #00d093;
}

.loader .circle:nth-child(4) {
  background-color: #ff4c52;
} */

/* @keyframes loader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 8px;
  animation: loader 1s infinite ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.loader .circle:nth-child(1) {
  animation-delay: 0s;
}

.loader .circle:nth-child(2) {
  animation-delay: 0.2s;
}

.loader .circle:nth-child(3) {
  animation-delay: 0.4s;
}

.loader .circle:nth-child(4) {
  animation-delay: 0.6s;
}

.loader .circle:nth-child(1) {
  background-color: #ff7f00;
}

.loader .circle:nth-child(2) {
  background-color: #1f8ef1;
}

.loader .circle:nth-child(3) {
  background-color: #00d093;
}

.loader .circle:nth-child(4) {
  background-color: #ff4c52;
} */

@keyframes loader {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 64px;
}

.loader .bar {
  width: 4px;
  height: 0;
  margin: 0 2px;
  background-color: #ffffff;
  animation: loader 0.4s infinite alternate ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.loader .bar:nth-child(1) {
  animation-delay: 0s;
}

.loader .bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loader .bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loader .bar:nth-child(4) {
  animation-delay: 0.3s;
}

.loader .bar:nth-child(1) {
  background-color: #ff7f00; /* Orange */
}

.loader .bar:nth-child(2) {
  background-color: #1f8ef1; /* Blue */
}

.loader .bar:nth-child(3) {
  background-color: #00d093; /* Green */
}

.loader .bar:nth-child(4) {
  background-color: #ff4c52; /* Red */
}

