.qr-generator-block {

}

.qr-generator-block > div:first-of-type{
    display: inline-block;
    padding: 2rem 20rem 2rem 2rem;
    width: calc(74% - 26rem - 8px); 
    /* 100% - 26% of Subscription box */
}

.qr-generator-block > div:nth-of-type(2){
    margin-top: 120px;
}

.qr-generator-heading {
    margin: 40px 0;
}