.copyTextContainer {
  position: relative;
  border-radius: 8px;
  padding: 20px;
  min-width: 350px;
  background: var(--er-lightGrey);
  white-space: pre-line;  
}

.copyTextContainer svg {
  cursor: pointer;
  color: var(--er-grey);
  position: absolute;
  right: 20px;
  top: 20px;
}

.copiedSpan {
  position: absolute;
  top: 55px;
  right: 0;
  background: white;
  padding: 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  transition: 0.2s all ease;
}

.showSpan {
  opacity: 100;
}

.hideSpan {
  opacity: 0;
}

.copyText {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 24px);
}
